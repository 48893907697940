export const data = [
  {
    no: 1,
    name: "Sok",
    date: "20-12-2020",
    amount: 10,
  },
  {
    no: 2,
    name: "Sok",
    date: "20-12-2020",
    amount: 10,
  },
  {
    no: 3,
    name: "Sok",
    date: "20-12-2020",
    amount: 10,
  },
  {
    no: 4,
    name: "Sok",
    date: "20-12-2020",
    amount: 10,
  },
  {
    no: 5,
    name: "Sok",
    date: "20-12-2020",
    amount: 10,
  },
];
